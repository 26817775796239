// COLOR
$color-bg: #1A1A1A;
$color-primary: #FAFAFA;
$color-icon-bg: #252525;
$color-icon-border-hover: #3E3E3E;
$color-icon-border-focus: #999999;
$color-text-title: #FAFAFA;
$color-text-article: #CCCCCC;

// FONT & TYPOGRAPHY
$font-family: 'Inter', Arial, sans-serif;;
$font-size: 18px;

