$max-width: 700px;
$gutter: 20px;
$gap: 48px;

html {
  color-scheme: dark;
}

body {
  background-color: $color-bg;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 1.5;
}

h1, h2, h3 {
  line-height: 1.2;
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: $color-text-title;
}

p {
  font-weight: normal;
  color: $color-text-article;
}

.container {
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding: $gap;

  @include for-phone-only {
    padding: $gutter;
    max-width: 100%;
  }
}
